import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import Nav from '../components/nav'
import ExploreIcon from '../components/explore'
import IntroVideo from '../videos/crown.mp4'

const Index = ({ data }) => {
  return (
    <>
      <SEO />
      <Nav logoFill="light">
        <div className="col text-left">
          <Link className="target fill-light" to="/explore" style={{height:"1.875rem"}}><ExploreIcon /></Link>
        </div>
      </Nav>

      <ReactFullpage
        // fullpage options
        licenseKey={'C1912978-A39F4CE4-9F6AC28B-DFB573DA'}
        anchors={['icon', 'luxury', 'arrival', 'lobby', 'flow', 'workspaces', 'crown']}
        navigation
        slidesNavigation
        navigationTooltips={['The Icon', 'The Luxury', 'The Arrival', 'The Lobby', 'The Flow', 'The Workspaces', 'The Crown']}
        sectionsColor={['transparent', '#002856', '#002856', '#002856', '#002856', '#002856', '#002856']}
        lazyLoading
        controlArrows={false}
        scrollOverflow

        render={({ fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <section className="section" key="section0" id="section0">
                <video className="video" loop muted data-autoplay>
                  <source src={IntroVideo} type="video/mp4" />
                </video>

                <div className="position-absolute d-none d-md-flex flex-column align-items-end" style={{top:"126px", right:"38px"}}>
                  <a className="btn btn-link" href="https://ifacility.exchange106.my">iFacility</a>
                  <a className="btn btn-link" href="https://vms.exchange106.my">VMS</a>
                </div>

                <div className="container overlay bg-dark col-lg-6 col-md-8 col-10 ml-0 px-3 py-4 p-md-5">
                  <h2 className="title">
                    A brand new icon<br/>
                    is born
                  </h2>
                  <div className="py-md-3">
                    <p>With the single tower confidently reaching for the sky, Exchange 106 is the embodiment of elegant sophistication and the nation’s ambitions . It is a breathtaking building, which makes its presence felt. In doing so, it will not only attract great ideas, partnerships and businesses, but also create them.</p>
                    
                    <p className="mb-0">Its 106 floors, soaring over the city will be a beacon visible for miles around, a symbol of a nation on the rise. The striking architecture and considered design gives home to unrivalled space, opportunities and facilities, ensuring that it will become one of the most sought after business destinations not just in South East Asia, but in the world.</p>
                  </div>
                </div>
              </section>

              <section className="section" key="section1" id="section1" style={{ backgroundImage: `url(${data.section1.childImageSharp.fluid.src})`,
  backgroundPosition:"right top" }}>
                <div className="container overlay bg-dark col-lg-8 offset-lg-4 col-10 offset-2 mr-0 pl-3 pr-5 py-4 p-md-5">
                  <h2 className="title">
                    Welcome to a new<br/>
                    standard of luxury
                  </h2>
                  <div className="row py-3">
                    <div className="col-md">
                      <p>The approach to the building is an experience in itself, as you travel through manicured lawns, past sculptures and water features. The sense of arrival at Exchange 106 is unparalled – making clear that you are about to enter a mesmerising place.</p>
                    </div>

                    <div className="col-md">
                      <p className="mb-0">The sweeping drive delivers you straight under the shady embrace of the porte cochere, where people will be greeted by the modern, art deco styling, fashioned using the finest natural stone paving and combined with a striking stainless-steel design.</p>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-12 d-md-flex">
                      <Link to="/explore" className="btn btn-primary ml-md-auto">Inside Exchange 106</Link>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section" key="section2" id="section2" style={{ backgroundImage: `url(${data.section2.childImageSharp.fluid.src})` }}>
                <div className="container overlay bg-dark col-lg-5 offset-lg-7 col-md-8 offset-md-4 col-11 offset-1 mr-0 pl-3 pr-5 py-4 p-md-5">
                  <h2 className="title">
                    The Arrival
                  </h2>
                  <div className="py-3">
                    <p>This is an incomparable arrival. The whole approach is as artfully choreographed as the structure stretching high above you.</p>

                    <p>Sweeping and rising as you move along the drive, the lawns, water and sculptures all come to life – reflecting on the building’s outer glass, creating an unbreakable bond with its park green surroundings.</p>

                    <p className="mb-0">It is a journey in itself, and you would be forgiven for wanting to go around again.</p>
                  </div>
                </div>
              </section>

              <section className="section" key="section3" id="section3" style={{ backgroundImage: `url(${data.section3.childImageSharp.fluid.src})` }}>
                <div className="container overlay bg-dark col-lg-5 col-md-8 col-10 ml-0 px-3 py-4 p-md-5">
                  <h2 className="title">
                    The Lobby
                  </h2>
                  <div className="py-3">
                    <p>To step into the lobby is to sense time stand still.</p>

                    <p>Mesmerized, the stainless steel elliptical loop that seemingly floats in the mass of open air pulls your eyes upwards, whilst the combination of soft marble, wood and light creates a warming embrace.</p>

                    <p className="mb-0">Soon, the flow of life through the space provides an invigorating energy that moves you – forward, and in.</p>
                  </div>
                </div>
              </section>

              <section className="section" key="section4" id="section4" style={{ backgroundImage: `url(${data.section4.childImageSharp.fluid.src})` }}>
                <div className="container overlay bg-dark col-lg-5 offset-lg-7 col-md-8 offset-md-4 col-11 offset-1 mr-0 pl-3 pr-5 py-4 p-md-5">
                  <h2 className="title">
                    The Flow
                  </h2>
                  <div className="py-3">
                    <p>Despite the scale and size, nowhere ever feels far away. With simplicity and speed designed into every aspect, it is as if you are being carried effortlessly from one place to another.</p>

                    <p className="mb-0">Efficiency, however, never compromises enjoyment. People flow comfortably through the naturally lit atriums, whilst feature escalators carve out epic visual connection between levels.</p>
                  </div>
                </div>
              </section>

              <section className="section" key="section5" id="section5">
                <div className="slide" key="slide1" id="slide1" style={{ backgroundImage: `url(${data.section5slide1.childImageSharp.fluid.src})`, backgroundPosition:"top", backgroundSize:"cover" }}>
                  <div className="container overlay bg-dark col-lg-5 col-md-8 col-10 ml-0 px-3 py-4 p-md-5">
                    <h2 className="title">
                      The Workspaces
                    </h2>
                    <div className="py-3">
                      <p>The sense of space and openness that greets you as you step onto each floor is a breath of fresh air.</p>

                      <p>Unhindered by columns, and awash with softening daylight, the offices masterfully balance lofty and handsome, intimate and expansive, function and form.</p>

                      <p>With sheer glass walls wrapping each soaring floor, the spectacular urban vistas ensure you are always placed at the heart of the action.</p>

                      <p className="mb-0">To come to work here each morning is nothing but enviable.</p>
                    </div>

                    <div className="row">
                      <div className="col-12 text-right">
                        <button 
                          className="btn btn-link" 
                          onClick={() => fullpageApi.moveSlideRight()}
                        >
                          Reimagining the workplace &rarr;
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="slide" key="slide2" id="slide2">
                  <div className="container overlay col-md-8 col-11 ml-0 pt-4 px-3 pb-0 pt-md-5 px-md-5 pb-md-3">
                    <h2 className="title">
                      Reimagining<br/>
                      the workplace
                    </h2>
                    <div className="row py-3">
                      <div className="col-md">
                        <p>The office spaces of Exchange 106 have been designed to attract the brightest people and businesses from around the world. The floors boasts up to 32,000 square feet of column free office space - the most anywhere in Malaysia - specially designed for maximum flexibility and efficiency.</p>

                        <p className="mb-0 d-none d-md-block">All of the floors enjoy spectacular 360-degree views, and all offer an unrivalled working</p>
                      </div>

                      <div className="col d-block d-md-none">
                        <p>All of the floors enjoy spectacular 360-degree views, and all offer an unrivalled working environment courtesy of the advanced cool light coated floor to ceiling windows, providing abundant natural light with minimum heat.</p>
                      </div>

                      <div className="col-md">
                        <p className="d-none d-md-block">environment courtesy of the advanced cool light coated floor to ceiling windows, providing abundant natural light with minimum heat.</p>

                        <p className="mb-0">Exchange 106's dedicated team are available free of charge to all tenants, to provide complete layout planning, interior design and material selection ensuring a smooth experience at each and every stage of the process.</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <button 
                          className="btn btn-link" 
                          onClick={() => fullpageApi.moveSlideLeft()}
                        >
                          &larr; The workspaces
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="container col-md-8 ml-0 pb-md-5 pl-0">
                    <img width="100%" height="100%" src={data.section5slide2a.childImageSharp.fluid.src} alt="Reimagining the workplace" />
                  </div>
                </div>
                <img className="d-none d-md-block" width="auto" height="90%" src={data.section5slide2b.childImageSharp.fluid.src} style={{ position:"absolute", bottom:"0", right:"6%" }} alt="Exchange 106"></img>
              </section>

              <section className="section" key="section6" id="section6" style={{ backgroundImage: `url(${data.section6.childImageSharp.fluid.src})`,
  backgroundPosition:"center top" }}>
                <div className="container overlay bg-dark col-lg-5 offset-lg-7 col-md-8 offset-md-4 col-11 offset-1 mr-0 pl-3 pr-5 py-4 p-md-5">
                  <h2 className="title">
                    The Crown
                  </h2>
                  <div className="py-3">
                    <p>Juxtaposing the daring simplicity of the tower, a prismatic capsule sits majestically atop Exchange 106.</p>

                    <p>Filled with light and imagination, it beams outward, creating a beautiful silhouette bringing out the building’s full glory.</p>

                    <p>From the outside, from ground level, the blue-silver hues occupy a permanent space in the city sky reflections by day, rays by night.</p>

                    <p className="mb-0">It speaks of a desire that cannot be extinguished, and a future that is only getting brighter.</p>
                  </div>
                </div>
              </section>
            </ReactFullpage.Wrapper>
          )
        }}
      />
    </>
  )
}

export default Index

export const query = graphql`
  query {
    section1: file(name: {eq: "index-section1"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    section2: file(name: {eq: "index-section2"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    section3: file(name: {eq: "index-section3"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    section4: file(name: {eq: "index-section4"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    section5slide1: file(name: {eq: "index-section5-slide1"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    section5slide2a: file(name: {eq: "index-section5-slide2a"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    section5slide2b: file(name: {eq: "index-section5-slide2b"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    section6: file(name: {eq: "index-section6"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
  }
`